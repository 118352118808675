import Spinner from "@/components/Spinner";
import useLists from "@/src/lists/hooks/useLists";
import useUserElements from "@/src/lists/hooks/useUserElements";
import { elementInListsWarning } from "@/src/lists/lib/language";
import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { observer } from "mobx-react";
import { useState } from "react";

dayjs.extend(relativeTime);

const AllSavedRecipesControl = ({ elementId, checked }) => {
  const { addElement, removeElement } = useUserElements({
    shouldFetch: true,
    fetchElementIdsOnly: true,
  });

  const { listElementIds } = useLists();

  const [isHovering, setIsHovering] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleToggle = async () => {
    setIsUpdating(true);

    if (checked) {
      if (listElementIds.includes(elementId)) {
        const confirmRemove = window.confirm(elementInListsWarning);
        if (confirmRemove) {
          await removeElement(elementId);
        }
      } else {
        await removeElement(elementId);
      }
    } else {
      await addElement(elementId);
    }
    setIsUpdating(false);
  };

  return (
    <div
      className={clsx(
        "flex h-12 w-full min-w-52 items-center font-body text-sm"
      )}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className={clsx(
          "w-full flex-1 overflow-x-hidden overflow-ellipsis whitespace-nowrap px-4"
        )}
      >
        All Saved Recipes
      </div>
      <div className={clsx("ml-auto")}>
        <button
          className={clsx("flex size-12 items-center justify-center")}
          onClick={handleToggle}
        >
          {isUpdating ? (
            <Spinner size="sm" />
          ) : checked ? (
            isHovering ? (
              <XMarkIcon
                className={clsx("size-4.5")}
                aria-label="Remove from list"
              />
            ) : (
              <CheckIcon className={clsx("size-4.5")} aria-label="In list" />
            )
          ) : isHovering ? (
            <PlusIcon className={clsx("size-4.5")} aria-label="Add to list" />
          ) : null}
        </button>
      </div>
    </div>
  );
};

export default observer(AllSavedRecipesControl);
