import clsx from "clsx";
import { forwardRef } from "react";

interface PopoverArrowProps {
  theme?: string;
}

const PopoverArrow = forwardRef<unknown, PopoverArrowProps>(
  (props: PopoverArrowProps, ref) => {
    const { theme = "dark" } = props;

    return (
      <svg
        width={19}
        height={7}
        viewBox="0 0 11 5"
        className={clsx("relative -top-[2px] ", {
          "[&_path]:fill-white [&_path]:stroke-white": theme === "light",
          "[&_path]:fill-zinc-900 [&_path]:stroke-zinc-900": theme === "dark",
        })}
        ref={ref as React.Ref<unknown>}
      >
        <path
          d="M0 0 L5.5 5 L11 0"
          strokeWidth="1"
          vectorEffect={"non-scaling-stroke"}
        />
      </svg>
    );
  }
);

PopoverArrow.displayName = "PopoverArrow";

export default PopoverArrow;
