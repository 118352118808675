import MenuList from "@/components/MenuList";
import Spinner from "@/components/Spinner";
import TooltipPopover from "@/components/TooltipPopover";
import useCurrentUser from "@/hooks/useCurrentUser";
import usePaywall from "@/hooks/usePaywall";
import { useStores } from "@/hooks/useStores";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import ListsControls from "@/src/lists/components/ListControls";
import useUserElements from "@/src/lists/hooks/useUserElements";
import { BookmarkIcon, CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";

type Recipe = {
  id: number;
  [key: string]: unknown;
};

const SaveRecipe = ({
  recipe,
  theme = "dark",
  size = "sm",
  side = "right" as const,
  onClick = () => null,
  shouldSaveToDefaultMenu = true,
  avoidCollisions = true,
  showLabel = false,
  border = false,
}: {
  recipe: Recipe;
  theme?: string;
  size?: "sm" | "xs" | "lg";
  side?: "right" | "left";
  onClick?: () => void;
  shouldSaveToDefaultMenu?: boolean;
  avoidCollisions?: boolean;
  showLabel?: boolean;
  border?: boolean;
}) => {
  // Make sure the id of the recipe is a number to avoid any comparison issues
  recipe = { ...recipe, id: Number(recipe.id) };

  const { menuStore } = useStores();
  const { setPaywall } = usePaywall();
  const { subscriptionPermission } = useSubscriptionPermission(recipe);
  const { canUseRecipeSaving } = subscriptionPermission;
  const { currentUser, isLoggedIn } = useCurrentUser();

  const iconClassName = clsx({
    "size-4.5": size === "xs",
    "size-5": size === "sm" || size === "lg",
  });

  const [icon, setIcon] = useState(<BookmarkIcon className={iconClassName} />);
  const [isSaving, setIsSaving] = useState(false);

  const hasListsMigrated = currentUser?.listsMigrated;

  const { elementIds, addElement, isAdding, isLoadingElementIds } =
    useUserElements({
      shouldFetch: isLoggedIn && hasListsMigrated,
      fetchElementIdsOnly: true,
    });

  const isRecipeSavedInMenuStore = menuStore.isRecipeSaved(recipe);
  const isRecipeSaved = elementIds.includes(recipe.id);

  useEffect(() => {
    let isLoading = false;
    let savedState = false;

    if (hasListsMigrated) {
      isLoading = isLoadingElementIds || isAdding;
      savedState = isRecipeSaved;
    } else {
      isLoading = menuStore.isFetching;
      savedState = isRecipeSavedInMenuStore;
    }

    setIsSaving(isLoading);

    const iconComponent =
      !canUseRecipeSaving || (!isLoading && !savedState) ? (
        <BookmarkIcon className={iconClassName} />
      ) : isLoading ? (
        <Spinner size="md" />
      ) : (
        <CheckIcon className={iconClassName} />
      );

    setIcon(iconComponent);
  }, [
    canUseRecipeSaving,
    hasListsMigrated,
    isAdding,
    isLoadingElementIds,
    isRecipeSaved,
    isRecipeSavedInMenuStore,
    menuStore.isFetching,
    recipe.id,
  ]);

  const handleClick = useCallback(
    ({ setIsPopoverOpen, setIsPopoverActive }) => {
      if (!canUseRecipeSaving) {
        setPaywall({
          isOpen: true,
          isClosable: true,
          variant: "saveAndCreateCollection",
          title: recipe.title,
        });
        onClick();
        return;
      }

      if (hasListsMigrated) {
        if (!isRecipeSaved) {
          addElement(recipe.id);
        }
      } else {
        menuStore.setActiveSaveRecipe(recipe);
        if (shouldSaveToDefaultMenu) addToDefaultMenu();
      }

      setIsPopoverOpen(true);
      setIsPopoverActive(true);
      onClick();
    },
    [
      canUseRecipeSaving,
      recipe.title,
      onClick,
      hasListsMigrated,
      elementIds,
      shouldSaveToDefaultMenu,
    ]
  );

  const addToDefaultMenu = useCallback(() => {
    if (menuStore.isFetching) return;
    if (menuStore.defaultMenu) {
      if (!menuStore.defaultMenu.isInMenu(recipe)) {
        menuStore.defaultMenu.addRecipe(recipe);
      }
    } else {
      menuStore.createDefaultMenu(recipe);
    }
  }, [menuStore, recipe]);

  return (
    <div className="SaveRecipe">
      <TooltipPopover
        disabled={isSaving}
        onClick={handleClick}
        side={side}
        avoidCollisions={avoidCollisions}
        hideWhenDetached={false}
      >
        <TooltipPopover.TooltipTrigger
          className={clsx(
            "flex shrink-0 items-center justify-center space-x-1.5 rounded-full  transition ease-out",
            {
              "": !showLabel,
              "w-full pl-4 pr-5 md:w-auto": showLabel,
              "h-component-sm": size === "xs",
              "w-component-sm": size === "xs" && !showLabel,
              "h-component": size === "sm",
              "w-component": size === "sm" && !showLabel,
              "h-component-lg": size === "lg",
              "w-component-lg": size === "lg" && !showLabel,
              "bg-zinc-50 text-zinc-950 hover:bg-zinc-200 hover:text-zinc-950":
                theme === "light",
              "bg-zinc-950 text-zinc-50 hover:bg-white hover:text-zinc-950":
                theme === "dark",
              "border-[1.5px] border-transparent": border,
              "border-zinc-950 hover:border-zinc-950":
                border && theme === "dark",
              "border-zinc-950": border && theme === "light",
            }
          )}
          label="Save Recipe & Create Collection"
        >
          <div>{icon}</div>
          {showLabel && <div className={clsx("font-body text-sm")}>Save</div>}
        </TooltipPopover.TooltipTrigger>
        <TooltipPopover.TooltipContent>
          Save Recipe & Create Collection
        </TooltipPopover.TooltipContent>
        <TooltipPopover.PopoverContent>
          <div className={clsx("", { hidden: isSaving })}>
            {hasListsMigrated ? (
              <ListsControls entry={recipe} />
            ) : (
              <MenuList recipe={recipe} />
            )}
          </div>
        </TooltipPopover.PopoverContent>
      </TooltipPopover>
    </div>
  );
};

export default observer(SaveRecipe);
