import Spinner from "@/components/Spinner";
import AllSavedRecipesControl from "@/src/lists/components/AllSavedRecipesControl";
import CreateListForm from "@/src/lists/components/CreateListForm";
import ListControl from "@/src/lists/components/ListControl";
import useLists from "@/src/lists/hooks/useLists";
import useUserElements from "@/src/lists/hooks/useUserElements";
import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { observer } from "mobx-react";
import { useRef } from "react";

dayjs.extend(relativeTime);

const ListsControls = ({ entry }) => {
  const listContainerRef = useRef(null);

  const { elementIds } = useUserElements({
    fetchElementIdsOnly: true,
  });

  const { lists, isLoading } = useLists();

  const handleCreateList = (newList) => {
    setTimeout(() => {
      if (listContainerRef.current) {
        listContainerRef.current.scrollTop =
          listContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  return (
    <div className={clsx("relative flex max-h-56 flex-col")}>
      <div
        ref={listContainerRef}
        className={clsx(
          "flex-grow overflow-y-auto overflow-x-hidden",
          "scrollbar scrollbar-thumb-white/20 scrollbar-w-1"
        )}
      >
        {isLoading ? (
          <div className={clsx("flex h-full items-center justify-center p-6")}>
            <Spinner size="sm" />
          </div>
        ) : (
          <ul>
            <li className={clsx("border-b border-b-zinc-800 last:border-b-0")}>
              <AllSavedRecipesControl
                elementId={entry.id}
                checked={elementIds.includes(entry.id)}
              />
            </li>
            {lists.map((list) => {
              const isInList = list.elementIds?.includes(entry.id);

              return (
                <li
                  key={list.uid}
                  className={clsx("border-b border-b-zinc-800 last:border-b-0")}
                >
                  <ListControl
                    list={list}
                    elementId={entry.id}
                    checked={isInList}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className={clsx("relative flex-shrink-0")}>
        <div
          className={clsx(
            "pointer-events-none absolute bottom-full left-0 right-0 h-2",
            "bg-gradient-to-t from-zinc-900/50 to-transparent"
          )}
        ></div>
        <div className={clsx("border-t border-t-zinc-800 bg-zinc-950")}>
          <CreateListForm entry={entry} onCreateList={handleCreateList} />
        </div>
      </div>
    </div>
  );
};

export default observer(ListsControls);
