import PopoverArrow from "@/components/PopoverArrow";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { useRouter } from "next/router";
import {
  Children,
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from "react";

type TooltipPopoverContextType = {
  handleClick: () => void;
  disabled: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPopoverActive: React.Dispatch<React.SetStateAction<boolean>>;
  isMouseDown: boolean;
  isPopoverActive: boolean;
  isPopoverOpen: boolean;
  setIsMouseDown: React.Dispatch<React.SetStateAction<boolean>>;
  avoidCollisions: boolean;
};

const defaultPopoverState: TooltipPopoverContextType = {
  handleClick: () => null,
  disabled: false,
  setIsPopoverOpen: () => null,
  setIsPopoverActive: () => null,
  isMouseDown: false,
  isPopoverActive: false,
  isPopoverOpen: false,
  setIsMouseDown: () => null,
};

const TooltipPopoverContext = createContext(defaultPopoverState);

const useTooltipPopover = () => {
  const context = useContext(TooltipPopoverContext);
  if (!context) {
    throw new Error(
      "useTooltipPopover must be used within a TooltipPopoverProvider"
    );
  }
  return context;
};

const defaultOnClick = ({
  setIsPopoverOpen = () => null,
  setIsPopoverActive = () => null,
}: {
  setIsPopoverOpen: Dispatch<React.SetStateAction<boolean>>;
  setIsPopoverActive: Dispatch<React.SetStateAction<boolean>>;
}) => {
  setIsPopoverOpen(true);
  setIsPopoverActive(true);
};

const TooltipPopover = ({
  children,
  disabled = false,
  onClick = defaultOnClick,
  side = "top",
  avoidCollisions = true,
  hideWhenDetached = true,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: ({
    setIsPopoverOpen,
    setIsPopoverActive,
  }: {
    setIsPopoverOpen: Dispatch<React.SetStateAction<boolean>>;
    setIsPopoverActive: Dispatch<React.SetStateAction<boolean>>;
  }) => void;
  side?: "top" | "right" | "bottom" | "left";
  avoidCollisions?: boolean;
  hideWhenDetached?: boolean;
}) => {
  const router = useRouter();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverActive, setIsPopoverActive] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  // When the route changes, we set the popover to closed
  useEffect(() => {
    const hidePopover = () => {
      setIsPopoverOpen(false);
      setIsPopoverActive(false);
    };

    router.events.on("routeChangeStart", hidePopover);

    return () => {
      router.events.off("routeChangeStart", hidePopover);
    };
  }, [router.events]);

  const handleClick = () => {
    if (disabled) return;

    onClick({ setIsPopoverOpen, setIsPopoverActive });
  };

  const tooltipTrigger = Children.toArray(children).find(
    (child) => child.type === TooltipTrigger
  );
  const tooltipContent = Children.toArray(children).find(
    (child) => child.type === TooltipContent
  );
  const popoverContent = Children.toArray(children).find(
    (child) => child.type === PopoverContent
  );

  const contextValue = {
    handleClick,
    disabled,
    setIsPopoverOpen,
    setIsPopoverActive,
    isMouseDown,
    isPopoverActive,
    setIsMouseDown,
  };

  const handleOpenChange = (open) => {
    setIsPopoverOpen(open);
    if (!open) {
      setIsPopoverActive(false);
    }
  };

  return (
    <TooltipPopoverContext.Provider value={contextValue}>
      <Popover.Root open={isPopoverOpen} onOpenChange={handleOpenChange}>
        <Popover.Anchor>
          {tooltipTrigger ? tooltipTrigger : null}
        </Popover.Anchor>
        <Popover.Portal>
          <Popover.Content
            className={clsx(
              "TooltipPopover__content",
              "relative z-30 rounded bg-zinc-800 font-body text-sm text-white focus:outline-none"
            )}
            side={side}
            collisionPadding={20}
            hideWhenDetached={hideWhenDetached}
            onMouseLeave={() => {
              if (isPopoverActive) {
                //  setIsPopoverActive(false);
              }
              // setIsPopoverOpen(false);
            }}
            avoidCollisions={avoidCollisions}
          >
            {isPopoverActive && popoverContent}
            {!isPopoverActive && tooltipContent}
            <Popover.Arrow width={11} height={5} asChild>
              <PopoverArrow />
            </Popover.Arrow>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </TooltipPopoverContext.Provider>
  );
};

const TooltipContent = ({ children }) => {
  return <div className={clsx("tooltip-content text-center")}>{children}</div>;
};

const TooltipTrigger = ({ children, className = "", label = "" }) => {
  const {
    handleClick,
    disabled,
    setIsPopoverOpen,
    setIsPopoverActive,
    isMouseDown,
    isPopoverOpen,
    isPopoverActive,
    setIsMouseDown,
  } = useTooltipPopover();

  return (
    <button
      className={clsx("TooltipPopover__anchorButton", "relative", className, {
        "pointer-events-none": disabled,
      })}
      type="button"
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={() => {
        if (isPopoverOpen && isPopoverActive) {
          setIsPopoverActive(false);
        }
        setIsPopoverOpen(true);
      }}
      onMouseLeave={() => {
        if (!isMouseDown && !isPopoverActive) {
          setIsPopoverOpen(false);
        }
      }}
      onMouseDown={() => setIsMouseDown(true)}
      onMouseUp={() => setIsMouseDown(false)}
      aria-label={label}
    >
      {children}
    </button>
  );
};

const PopoverContent = ({ children }) => {
  return <div className={clsx("")}>{children}</div>;
};

TooltipPopover.TooltipTrigger = TooltipTrigger;
TooltipPopover.TooltipContent = TooltipContent;
TooltipPopover.PopoverContent = PopoverContent;

export default TooltipPopover;
